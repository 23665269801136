body {
    margin: 0;
    justify-content: center;
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
    font-size: 2rem;
    text-align: center;
    margin-top: 5rem;
}

h2 {
    margin-top: 5rem;
}

img{
    border-radius: 5px;
}

nav {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    @media (max-width: 576px) {
        width: calc(100%);
    }
}

div.mainmenusel {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

// @media screen and (max-width: 800px) {
//     .p-sidebar {
//         width: 100%;
//     }
// }

a.Link {
    font-size: 1.5rem;
    padding: 1rem 0;
    margin-right: 0.5rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    text-decoration: none;
    transition: color 0.3s linear;
    @media (max-width: 576px) {
        font-size: 1.5rem;
        text-align: center;
    }
    &:hover {
        color: "blue";
    }
}

.dym{
    color: rgb(255,255,255);
}



// .container {
//     position: relative;
//   }
//   .image-wrapper {
//     position: relative;

//   }
//   .tweetty {
//     overflow: auto;
//     top: 0;
//     left: 0;
//   }
//   .image-vest {
//     position: absolute;
//     top: 0;
//     left: 0;
//     background-color: #00f;
//     width: 220px;
//     height: 300px;
//     opacity: 0.4;
//     color: #fff;
//   }

.ql-video {background:green}

/*switch*/

.p-inputswitch-slider{
    background-color: lightgray !important;
    width:50px;
}

/*sidebar*/
.p-sidebar-close:focus{
    outline: none;
    border:none
}

/*editor*/
.p-editor-toolbar{
    background-color: whitesmoke !important;
}

.ql-active{
  background:lightblue !important;
  font-weight: bold;
}




/* For the galleria */
.galleria-demo {
    .custom-galleria {
        &.fullscreen {
            display: flex;
            flex-direction: column;

            .p-galleria-content {
                flex-grow: 1;
                justify-content: center;
            }
        }

        .p-galleria-content {
            position: relative;
        }

        .p-galleria-thumbnail-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
        }

        .p-galleria-thumbnail-items-container {
            width: 100%;
        }

        .custom-galleria-footer {
            display: flex;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.9);
            color: #ffffff;

            > button {
                background-color: transparent;
                color: #ffffff;
                border: 0 none;
                border-radius: 0;
                margin: 0.2rem 0;

                &.fullscreen-button {
                    margin-left: auto;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }

        .title-container {
            > span {
                font-size: 0.9rem;
                padding-left: 0.829rem;

                &.title {
                    font-weight: bold;
                }
            }
        }
    }
}

/* End of galleria */
