
.p-inputtext{
  width: calc(100% - 2.357em);
}

.p-inplace-display{
  min-width: 100px;
}


/* use section to lay out flex items */
section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg.standardsize {
    width: 1.5rem;
  }


