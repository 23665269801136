.fade-in-opacity {
    animation: fadeInOpacity linear 5s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.move-in-rect {
    animation-name: moveinrect;
    animation-duration: 4s;
    animation-delay: 2s;
}

.foreground-text {
    animation-name: foregroundtext;
    animation-delay: 1s;
    animation-duration: 3s;
    animation-iteration-count: 1;
}

@keyframes moveinrect {
    0% {
        left: 0px;
        top: 0px;
    }
    25% {
        left: 200px;
        top: 0px;
    }
    50% {
        left: 200px;
        top: 200px;
    }
    75% {
        left: 0px;
        top: 200px;
    }
    100% {
        left: 0px;
        top: 0px;
    }
}

@keyframes foregroundtext {
    0% {
        color: purple;
    }
    100% {
        color: white;
    }
}
